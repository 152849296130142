<template>
    <section class="section">
        <h2>SP-API設定</h2>

        <inline-loader v-if="account_loading"></inline-loader>
        <div v-else>
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-3">ステータス</div>
                        <div class="col-21">
                            <span v-if="!account.spapi_merchant_id" class="font-weight-bold text-warning">未連携</span>
                            <span v-else-if="account.spapi_error_count" class="font-weight-bold text-danger">エラー</span>
                            <span v-else class="font-weight-bold text-success">正常</span>
                        </div>
                    </div>
                    <div>
                        <template v-if="false === $store.getters['user/auth/canManageSpapi']()">
                            <div class="alert alert-warning">SP-API設定を変更する権限がありません</div>
                        </template>
                        <template v-else>
                            <a v-if="!account.spapi_merchant_id" :href="auth_url" class="btn btn-primary">連携実行</a>
                            <a v-else :href="auth_url" class="btn btn-primary">再連携</a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InlineLoader from '@/shared/components/tool/InlineLoader';

export default {
    name: 'PageSettingSpapi',
    components: {
        InlineLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            account_loading: true,
            account: null,
            auth_url: process.env.VUE_APP_API_BASE_URI + "/spapi/redirect",
            auth_query: {
                spapi_oauth_code: this.$route.query.spapi_oauth_code,
                state: this.$route.query.state,
                selling_partner_id: this.$route.query.selling_partner_id,
            }
        }
    },
    mounted() {
        if (this.auth_query.spapi_oauth_code) {
            this.auth();
        } else {
            this.init();
        }
    },
    methods: {
        init() {
            this.account_loading = true;
            this.$http.get('/me/account')
            .then((response) => {
                this.account = response.data;
                //auth情報も最新化
                this.$store.commit('user/auth/setAccount', this.account);
            })
            .finally(() => {
                this.account_loading = false;
            });
        },
        auth() {
            this.startScreenLoading();
            this.$http.put('/spapi/auth', this.auth_query)
            .then((response) => {
                this.account = response.data;
                this.account_loading = false;
                //auth情報も最新化
                this.$store.commit('user/auth/setAccount', this.account);
            })
            .catch(() => {
                this.init();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    },
}
</script>

<style scoped>

</style>
